<template>
  <v-container
    v-if="$isEnabledProperty($COMPRAR_ELEMENTOS_REGISTRALES)"
    id="crud"
    fluid
    tag="section"
    class="px-6"
  >
    <!--<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
			</v-toolbar> </v-card
		>-->
    <v-row
      ><v-col cols="auto" md="12" class="">
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          sort-by="secret"
          class="elevation-0 data__table"
          :footer-props="{
            'items-per-page-options': [10, 20],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat color="white" class="pa-0 pr-0">
              <v-row class="pa-0 pt-4 ml-0">
                <v-col sm="6" class="px-0 ml-0">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscador"
                    dense
                    filled
                    rounded
                    solo
                  ></v-text-field>
                </v-col>

                <v-col sm="6" class="pa-0 pt-2 ml-0 text-end">
                  <span class="text--primary-cart"
                    >${{ $formatMoney($store.state.carrito.subtotal) }}</span
                  >
                  <v-menu
                    bottom
                    right
                    offset-y
                    origin="top right"
                    transition="scale-transition"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        class="ml-2"
                        min-width="0"
                        text
                        v-bind="attrs"
                        v-on="on"
                        @click="force()"
                      >
                        <v-badge
                          v-if="$store.state.carrito.cantItem > 0"
                          color="primary"
                          overlap
                          bordered
                        >
                          <template v-slot:badge>
                            <span>{{ $store.state.carrito.cantItem }}</span>
                          </template>

                          <v-icon class="icon-25">mdi-cart</v-icon>
                        </v-badge>
                        <v-badge v-else overlap bordered>
                          <template v-slot:badge>
                            <span>0</span>
                          </template>

                          <v-icon class="icon-25">mdi-cart</v-icon>
                        </v-badge>
                      </v-btn>
                    </template>
                    <v-list
                      :tile="false"
                      class="pa-0 ma-0"
                      nav
                      dense
                      style="width: 400px"
                    >
                      <Carrito
                        :key="keyCarrito"
                        :merge-elementos="mergeElementoConCarrito"
                      />
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-toolbar>
            <h2>Articulos</h2>
          </template>

          <template v-slot:header.unidades="{ header }">
            <div class="d-flex align-center">
              <span>{{ header.text }}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="ml-2"
                    color="default"
                    style="
                      height: 18px;
                      width: 18px;
                      font-size: 10px;
                      background-color: black;
                    "
                  >
                    <i class="fa fa-info" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <span>La cantidad debe ser multiplo de la cantidad mínima</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.ForeignName`]="{ item }">
            <v-row>
              <v-col class="col-md-12 pt-4 pb-0 row-producto-item-name">
                {{ item.ForeignName | capitalizeFirst }}</v-col
              >
            </v-row>
            <v-row class="pt-2 mb-1">
              <v-col class="col-md-6 pa-0 px-3" style="margin-top: -10px">
                <v-row class="pt-2 mb-1">
                  <v-col class="col-md-2 pa-0 px-3" style="">
                    <span class="row-producto-item-code">{{
                      item.ItemCode
                    }}</span>
                  </v-col>

                  <v-col class="col-md-6 pa-0 px-3" style="">
                    <span
                      class="custom-badge"
                      :class="{
                        'badge-blue': item.db.substring(4, 6) === 'AC',
                        'badge-green': item.db.substring(4, 6) === 'MJ',
                      }"
                    >
                      {{ item.db.substring(4, 6) }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.priceList`]="{ item }">
            <v-row class="text-start">
              <v-col class="col-md-12 pt-2 pb-0"
                ><b>{{
                  item.priceList.Price != ""
                    ? "$ " + $formatMoney(item.priceList.Price)
                    : "&nbsp;"
                }}</b></v-col
              >
              <v-col class="col-md-12 pa-0 px-2" style="margin-top: -10px">
                <span class="row-unidades-compra-min"
                  >Compra min.
                  {{ Number(item.SalesQtyPerPackUnit) }} Unid.</span
                ></v-col
              >
            </v-row>
          </template>

          <template v-slot:[`item.unidades`]="{ item }">
            <div class="d-flex align-center">
              <v-btn
                x-small
                class="btn-decrement btn-mas-menos"
                :class="item.error != '' && item.error ? 'bg-error' : ''"
                @click="decrement(item)"
              >
                <v-icon style="font-size: 16px">mdi-minus</v-icon>
              </v-btn>

              <!-- Input de Unidades -->
              <!-- este input tiene el rules, pero funciona igual sin el rules
              <v-text-field
                v-model.number="item.unidades"
                type="number"
                @keydown="$validarSoloNumeros"
                class="row-unidades center-number-input no-border"
                hide-details
                height="25"
                :rules="[(valor) => $validarDivisor(item)]"
                :error-messages="item.error"
                @input="$validarDivisor(item)"
              ></v-text-field>
              -->
              <v-text-field
                v-model.number="item.unidades"
                type="number"
                @keydown="$validarSoloNumeros"
                class="row-unidades center-number-input no-border"
                hide-details
                height="25"
                :error-messages="item.error"
                @input="$validarDivisor(item)"
                :class="item.error != '' && item.error ? 'border-error' : ''"
              ></v-text-field>

              <v-btn
                x-small
                class="btn-increment btn-mas-menos"
                @click="increment(item)"
                :class="item.error != '' && item.error ? 'bg-error' : ''"
              >
                <v-icon style="font-size: 16px">mdi-plus</v-icon>
              </v-btn>
              <v-tooltip
                bottom
                v-if="item.error != '' && item.error"
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="ml-2 error"
                    style="height: 18px; width: 18px; font-size: 10px"
                  >
                    <v-icon style="font-size: 13px">mdi-close</v-icon>
                  </v-btn>
                </template>

                <span class="white--text text--small">{{ item.error }}</span>
              </v-tooltip>
            </div>
            <!--<div class="d-flex align-center">
              <span class="red--text text--small" v-if="item.error != ''" style="font-size: 10px;"
                >Las unidades deben ser múltiplos de la cantidad mínima ({{
                  Number(item.SalesQtyPerPackUnit)
                }})</span
              >
            </div>-->
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="$isEnabledProperty($COMPRAR_ELEMENTOS_REGISTRALES)"
              small
              rounded
              :class="
                item.disabled
                  ? ''
                  : item.buttonClass != ''
                  ? item.buttonClass
                  : 'btn-primary'
              "
              class="px-3"
              :disabled="item.disabled || item.disabled == null"
              @click="AgregarProductos(item)"
            >
              <small
                :class="
                  item.buttonClass != '' || !item.disabled
                    ? 'capitalize-first text-button-carrito'
                    : 'capitalize-first text-button-carrito-vacio'
                "
              >
                {{ item.buttonTxt }}
              </small>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>
<style scoped>
.capitalize-first {
  text-transform: lowercase; /* Convierte todo el texto a minúsculas */
}

.capitalize-first::first-letter {
  text-transform: uppercase; /* Capitaliza solo la primera letra */
}
</style>
<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Carrito from "@/views/carrito/Carrito.vue";
import AgregarProductos from "@/views/dashboard/components/secundarios/AgregarProductos.vue";
import Swal from "sweetalert2";

function title() {
  return "Elementos Registrales";
}

export default {
  components: {
    Carrito,
    AgregarProductos,
    //  Carrito,
  },

  data: (vm) => ({
    customErrorMessage: "",
    accionProducto: "",
    snackbar: false,
    text: "Registro Insertado",

    color: "success",
    timeout: 4000,
    title: title(),
    search: "",
    valid: true,

    keyCarrito: 0,
    dialog: false,
    readonly: true,
    readOnlySubtotal: true,
    headers: [
      {
        text: "Codigo",
        filterable: true,
        value: "ItemCode",
        align: " d-none",
      },
      {
        text: "Producto",
        filterable: true,
        value: "ForeignName",
        width: "45%",
      },
      { text: "P. Unitario", filterable: true, value: "priceList" },
      {
        text: "Unidades",
        filterable: true,
        value: "unidades",
        sortable: false,
        width: "25%",
      },
      { text: "", value: "actions", sortable: false },
    ],

    route: "elementosRegistrales",

    desserts: [],
  }),

  created() {
    //console.log(this.$store.state.carrito);
    this.getElementos();
  },

  mounted() {
    console.log("Componente " + this.baseUrl + " creado");
    this.$store.commit("SET_CART_EDIT_PATH", "/elementos_registrales");
    //this.fetchProductos();
    //this.resetSourceVentaElementosAduana();
  },

  computed: {
    ...mapState("carrito", ["carrito", "total"]),
  },

  methods: {
    //validarDivisor(valor, divisor, index) {

    async ruleIsMultiplo(value, divisor) {
      if (typeof value !== "undefined" && value != null && value !== "") {
        this.customErrorMessage = "";
        let resp = this.$isMultiplo(value, divisor);

        if (resp == 1) {
          this.customErrorMessage =
            "Las unidades deben ser múltiplos de la cantidad mínima (" +
            divisor +
            ")";
          //this.editedItem.datos_usuario.usuario = "";
          return false;
        } else {
          this.customErrorMessage = "";
          return true;
        }
      } else {
        this.customErrorMessage = "";
      }
    },
    getElementos() {
      console.log(this.$store.state.user);
      let userProps = this.$store.state.user.sapProperties;
      let formSap = {};
      const trueProps = Object.entries(userProps)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      //console.log(trueProps);
      trueProps.push(this.$store.state.user.sapProperties.PriceListNum);
      formSap.userProps = trueProps;
      Swal.alertGetInfo("Buscando información");
      this.$axiosApi
        .post(this.route, formSap)
        .then((r) => {
          if (r.data.data) {
            this.desserts = r.data.data;
            this.mergeElementoConCarrito();
          }

          Swal.close();
        })
        .catch(function (error) {
          console.log(error);
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";

          Swal.close();
        });
    },

    mergeElementoConCarrito() {
      let carritoData = this.$store.state.carrito.data; // El array del carrito

      this.desserts.forEach((dessert) => {
        // Encontrar si el ItemCode del dessert está en el carrito
        let carritoItem = carritoData.find(
          (item) => item.ItemCode === dessert.ItemCode
        );

        if (carritoItem) {
          dessert.unidades = carritoItem.unidades;
          dessert.error = carritoItem.error;
          dessert.disabled = false;
          dessert.buttonTxt = "Agregado al carrito";
          dessert.buttonClass = "btn-success";
        } else {
          dessert.unidades = "";
          dessert.error = "";
          dessert.disabled = true;
          dessert.buttonTxt = "Agregar al carrito";
          dessert.buttonClass = "";
        }

        dessert.TipoElementosId = 1;
        dessert.TipoElementosDesc = "Elementos Registrales";
      });
    },
    ...mapMutations("carrito" /*, ["editProducto"]*/),
    //...mapMutations("aduana", ["resetSourceVentaElementosAduana"]),

    AgregarProductos(item) {
      // Usa una mutation para agregar o actualizar el item en el carrito
      if (item.unidades > 0) {
        // Cambiar color y etiqueta del botón
        item.buttonClass = "btn-success";
        item.buttonTxt = "Agregado al carrito";
        item.error = "";
        this.keyCarrito++;
        this.$store.commit("carrito/addOrUpdateItem", item);

        this.$forceUpdate();
      } else {
        Swal.alert(
          "No es posible agregar el producto",
          "Para agregar el producto tenes que ingresar la cantidad de unidades"
        );
      }
    },
    force() {
      this.keyCarrito++;
      this.$forceUpdate();
    },
    editProductos(item) {},

    async increment(item) {
      /*let resp = await this.$validarDivisor(item);

      if (resp == false) {
        return;
      }*/
      /*
      // Buscar el índice del producto en el array 'desserts' por 'ItemCode'
      let index = this.desserts.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        // Actualizar las unidades del producto
        this.desserts[index].unidades =
          this.desserts[index].unidades == "" ||
          isNaN(this.desserts[index].unidades)
            ? 0
            : this.desserts[index].unidades;
        this.desserts[index].unidades += Number(
          this.desserts[index].SalesQtyPerPackUnit
        );
        this.desserts[index].disabled = false;
        // Forzar la reactividad reemplazando el objeto
        this.$set(this.desserts, index, { ...this.desserts[index] });
      }*/
      let divisor = Number(item.unidades) / Number(item.SalesQtyPerPackUnit);
      //let res = parseInt(Number(divisor));
      let index = this.desserts.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        // Actualizar las unidades del producto
        //this.desserts[index].unidades =  (res+1) * parseInt(Number(item.SalesQtyPerPackUnit));
        let NuevasUnidades =
          Math.ceil(
            item.unidades / Number(this.desserts[index].SalesQtyPerPackUnit)
          ) * Number(this.desserts[index].SalesQtyPerPackUnit);
        if (NuevasUnidades == item.unidades) {
          NuevasUnidades =
            Number(item.unidades) +
            Number(this.desserts[index].SalesQtyPerPackUnit);
        }

        this.desserts[index].unidades = NuevasUnidades;

        this.desserts[index].disabled = false;
        this.desserts[index].buttonTxt = "Agregar al carrito";
        this.desserts[index].buttonClass = "";
        this.desserts[index].error = "";
        // Forzar la reactividad reemplazando el objeto
        this.$set(this.desserts, index, { ...this.desserts[index] });
      }
    },
    async decrement(item) {
      /*let resp = await this.$validarDivisor(item);

      if (resp == false) {
        return;
      }*/

      // Buscar el índice del producto en el array 'desserts' por 'ItemCode'
      let index = this.desserts.findIndex((p) => p.ItemCode === item.ItemCode);

      if (index !== -1) {
        // Actualizar las unidades del producto
        if (
          this.desserts[index].unidades -
            Number(this.desserts[index].SalesQtyPerPackUnit) >
          0
        ) {
          /*this.desserts[index].unidades -= Number(
            this.desserts[index].SalesQtyPerPackUnit
          );*/

          let NuevasUnidades =
            Math.floor(
              item.unidades / Number(this.desserts[index].SalesQtyPerPackUnit)
            ) * Number(this.desserts[index].SalesQtyPerPackUnit);

          if (NuevasUnidades == item.unidades) {
            NuevasUnidades =
              Number(item.unidades) -
              Number(this.desserts[index].SalesQtyPerPackUnit);
          }

          this.desserts[index].unidades = NuevasUnidades;
          this.desserts[index].disabled = false;
          this.desserts[index].buttonTxt = "Agregar al carrito";
          this.desserts[index].buttonClass = "";
          this.desserts[index].error = "";
        } else {
          this.desserts[index].unidades = 0;

          this.desserts[index].disabled = true;
          this.desserts[index].buttonTxt = "Agregar al carrito";
          //this.desserts[index].buttonClass = '';

          this.desserts[index].error =
            "El valor ingresado debe ser mayor que 0";
        }

        // Forzar la reactividad reemplazando el objeto
        this.$set(this.desserts, index, { ...this.desserts[index] });
      }
    },
  },
  watch: {},
};
</script>
